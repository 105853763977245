/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";


// React differences
@import "react/react-differences";

.bg-cover {
	background-size: cover !important;
	min-height: 100vh;
	max-width: 100vw;
}

.text-escape {
	color: #0b2564
}

.font-weight-max {
	font-weight: 900;
}

// home
.bg-header-home {
	background: url(../img/home/home.jpg) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 270px;
	color: #fff;
}

.title-promotion {
	font-size: 25px;
	font-weight: 700;
}

.custom__promotion .slick-list .slick-slide {
	padding: 0 8px;
	box-sizing: border-box;
}

.menu-home {
	// height: 50px;
	// line-height: 3;
	// border-radius: 5px;
	// background-color: #fff;
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 3;
	border-radius: 50%;
	background-color: #fff;
}

.title-silder {
	color: black;
	font-weight: 700;
	font-size: 18px;
}

.box-radius {
	background-color: #fff;
	position: absolute;
	top: 260px;
	border-radius: 9px;
}

.box-radius-card {
	position: relative;
	bottom: 60px;
}

#congratulation {
	position: fixed;
	top: 70px;
	z-index: 9999;
	width: 100%;

}

@font-face {
	font-family: 'Helena';
	src: url('../fonts/helena/Helena.otf');
	src: url('../fonts/helena/Helena.ttf') format('truetype');
}

#font-congratulation {
	font-family: Helena;
}

//Lucky spin----------------------------
.wheel {
	display: inline-block;
	position: relative;
	overflow: hidden;
}

#bg-wheel {
	background: url(../img/spin/Frame.png) no-repeat center center;
	background-size: cover;
	width: 345px;
	height: 345px;
}

.wheel:after {
	content: "";
	// background: red;
	background: url(../img/spin/marker.png);
	// background-size: cover;
	// border: 2px solid white;
	// position: absolute;
	// top: -10px;
	// left: 50%;
	// width: 20px;
	// height: 20px;
	// margin-left: -7px;
	// transform: rotate(45deg)
	background-size: cover;
	/* border: 2px solid white; */
	position: absolute;
	top: -30px;
	left: 26%;
	width: 21%;
	height: 100px;
	margin-left: 39px;
	transform: rotate(0deg);
}

.spin-wheel {
	margin: 0 auto;
	-webkit-animation: rotate 1s linear infinite;
	animation: rotate 1s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.form-customize {
	position: relative;
	min-height: 3em;
}

input.form-customize-input {
	height: 3em;
	position: absolute;
	top: 0;
	padding: 1.2rem 0.75rem 0.625rem;
	left: 0;
}

label.form-customize-label {
	position: absolute;
	font-size: .7em;
	top: .17rem;
	left: 0.75rem;
}

//card
.radio_card_label {
	width: 100px;
	height: 70px;
	border: 1px solid #adb5bd;
	text-align: center;
	border-radius: 5px;
	display: inline-block;
	font-size: 30px;
	line-height: 65px;
	font-weight: 700;
	color: #075893;
	cursor: pointer;
}

input[type="radio"]:checked + label{
	background-color: #0085e7;
	color: #fff;
}

// slider
#ReactBackgroundSlider > figure {
	background-attachment: fixed;
	background-position: top center!important;
	position: fixed!important;
}

//lucky spin
#bg-lucky-spin {
	background: url(../img/spin/img@3x.png) no-repeat center center fixed;
	background-size: cover;
	width: 100%;
	position: absolute;
	height: 100%;
}

#menu-lucky-spin {
	background-color: #fff;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.item-lucky-spin {
	font-size: 15px;
	text-align: center;
	flex-grow: 1;
	margin-top: 10px;
}

.item-lucky-spin img{
	max-width: 25px;
}

// referral history

#count-result {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #e47216;
	border-radius: 10px;
}

.item-count-result {
	flex-grow: 1;
	text-align: center;
	padding: 5px;
	color: #fff;
}

.item-count-result h2{
	color: #fff;
	font-size: 30px;
	font-weight: 700;
}

// gifd card
.tab-gifd-card {
	height: 50px;
	line-height: 40px;
	font-size: 15px;
	font-weight: 600;
	color: #adb5bd;
	width: 50%;
	text-align: center;
}
.nav-tabs .nav-link.active {
	border: none;
	border-bottom: 3px solid #0085e7;
	color: #0085e7!important;
}

// history appointment
#list-history-appointment {
	height: 600px;
	width: 100%;
	overflow-y: scroll;
}

.item-history-appointment {
	border: 2px solid #e9ecef;
	border-radius: 5px;
}

.infor-item {
	background-color: #e9ecef;
	width: 100%;
	display: inline-block;
	padding: 15px;
}

// edit profile
.input-edit-profile {
	padding-left: 50px;
	padding-top: 18px;
	border: none;
	font-size: 15px;
	font-weight: 700;
	color: #000;
}

#account_infor .form-control:disabled, .form-control[readonly] {
	background-color: #fff;
}

.select_edit_profile {
	padding-left: 12px!important;
}

// About Salon
.nav-tabs .nav-link.tab_salon.active {
	border: none;
	border-bottom: 3px solid #000!important;
	color: #000!important;
}

.radio_category_name_label {
	width: 70px;
	height: 30px;
	border: 1px solid;
	text-align: center;
	border-radius: 5px;
	display: inline-block;
	font-size: 15px;
	line-height: 30px;
	font-weight: 600;
	cursor: pointer;
}

// Effect button
.btn-effect-click:active {
	background-color: #0085E7;
	transform: translateY(2px);
}